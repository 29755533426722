<template>
  <div class="profile-edit" v-if="!isProcessing">
    <profile-form @set-postparams="setPostParams" @set-blob="setBlob" :user="user"/>
    <v-btn class="profile-edit__btn" depressed :disabled="!isValid" @click="editUser()">プロフィールを編集する</v-btn>
  </div>
</template>
<script>
import ProfileForm from '@/components/common/form/user.vue'

export default {
  name: 'profile-edit',
  components: { ProfileForm },
  data () {
    return {
      postParams: {
        // プロフィール画像
        icon: '',
        // 名前
        name: '',
        // 自己紹介文
        profile: ''
      },
      // Blobファイル
      blob: null,
      // 画像の格納先のパス名
      path: ''
    }
  },
  mounted () {
    // ユーザー情報の格納
    if (this.user) {
      Object.keys(this.postParams).forEach(key => {
        this.postParams[key] = this.user[key] || ''
      })
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} プロフィールが登録可能かどうか
     */
    isValid () {
      const isFilled = Object.values(this.postParams).filter(value => value.trim().length > 0).length ===
             Object.values(this.postParams).length
      const isCorrectProfile = this.postParams.name.length <= 20 && this.postParams.profile.length <= 160
      return isFilled && isCorrectProfile
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    }
  },
  methods: {
    /**
     * ユーザー情報の更新
     */
    async editUser () {
      this.$store.commit('setSubmitting', true)

      // 画像の更新
      await this.updateImage()

      // ユーザー情報の更新
      await this.updateUser()

      // 処理完了。画面遷移
      this.$store.commit('setTelop', { show: true, msg: 'プロフィール情報を更新しました', type: 'success' })
      this.$router.push({ name: 'home' })
    },
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * Blobファイルの代入
     * @param {Blob} blob 格納するBlobファイル
     * @param {String} path 格納する画像の格納先のパス名
     */
    setBlob (blob, path) {
      this.blob = blob
      this.path = path
    },
    /**
     * 画像の更新
     */
    async updateImage () {
      // Blobファイルがない場合は終了
      if (!this.blob) return

      // 画像のアップロード
      const url = await this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blob, path: this.path })
      this.postParams.icon = url

      // 既存の画像の削除
      // 削除はいつ終わっても問題ないので、非同期処理
      if (this.user && this.user.icon) this.$store.dispatch('cloudstorage/deleteImage', this.user.icon)
    },
    /**
     * ユーザー情報の更新
     */
    async updateUser () {
      // 日時情報の追加
      this.postParams.updatedAt = new Date()

      // ユーザー情報の更新
      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: this.postParams })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile-edit {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__btn {
    display: block;
    margin: 30px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 124px;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
